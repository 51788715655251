/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */

import { observable } from 'mobx'
import _ from 'underscore'

import { ImageMetadata } from './ImageMetadata'
import { MARBLEImages } from './MARBLEImages'
import { RefRange } from './RefRange'
import { fmt } from '../components/utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ImagesRoot')

/**
 * Common data shared by all controls used in the right hand side images pane
 */

const fetchChapterInfo = async (chapters: string[], signal: AbortSignal) => {
    let results: ImageMetadata[] = []

    for (const bbbccc of chapters) {
        const result = await MARBLEImages.fetchInfo(bbbccc, { signal })
        results = results.concat(result)
    }

    return results
}

// WARNING: Passing too many references may cause long search times
const getImagesInRange = async (references: RefRange[], signal: AbortSignal) => {
    // fetch images for all chapters in range
    const bbbcccs = references.flatMap((ref) => [...ref.chapterIterator()])
    const images = await fetchChapterInfo(bbbcccs, signal)

    // Filter out images that don't fall in exact verse ranges specified
    return images.filter((image) => image.fallsInRanges(references))
}

const getImagesForRefs = async (refs: RefRange[], signal: AbortSignal) => {
    try {
        let images = await getImagesInRange(refs, signal)
        log('getImagesForRefs', fmt({ refs, length: images.length }))

        const ids = new Set<string>()
        const first = (id: string) => {
            if (ids.has(id)) return false
            ids.add(id)
            return true
        }

        // Filter out all but the first occurrence of each image
        images = images.filter((img) => first(img.id))
        images = _.sortBy(images, (img) => img.sortKey)

        return images
    } catch (err) {
        const error = err as Error
        if (error.name !== 'AbortError') {
            log('###', error)
            throw err
        }
        return []
    }
}

export class ImagesRoot {
    @observable images: ImageMetadata[] = []

    @observable loading = false

    private controller = new AbortController()

    private numFinished = 0

    private numStarted = 0

    /**
     * If the user pass a large range it may trigger reading the MARBLE image data
     * for many chapters. Because of this we use an AbortController to allow canceling
     * all these reads if we move to a different reference.
     * This controller only affects the MARBLE images (not the project images) to it
     * is odd for it to be in a class that I think should just contain the code
     * that is common to MARBLE and project images.
     */
    setSearchRefs = async (searchRefs: RefRange[]) => {
        log('ImagesRoot setSearchRefs', JSON.stringify(searchRefs))

        try {
            log('fetchImages', JSON.stringify(searchRefs))
            this.controller.abort()

            if (searchRefs.length === 0) {
                this.images = []
                return
            }

            const newController = new AbortController() // Need a new controller since current one has been aborted
            this.controller = newController

            this.numStarted += 1
            if (this.numStarted !== this.numFinished) {
                this.loading = true
            }

            const images = await getImagesForRefs(searchRefs, newController.signal)

            this.numFinished += 1 // Will get called when request is aborted or finishes
            if (this.numStarted === this.numFinished) {
                this.loading = false
            }

            this.images = images

            log('ImagesRoot setSearchRefs found=', this.images.length)
        } catch (error) {
            this.images = []
            this.numStarted = 0
            this.numFinished = 0
            this.loading = false
            console.log('### cannot fetch images', error)
        }
    }
}
