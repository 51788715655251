/* eslint-disable import/no-cycle */

import _ from 'underscore'

import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { ProjectPlan } from '../../../models3/ProjectPlan'
import { ProjectStage } from '../../../models3/ProjectStage'
import { ProjectTask } from '../../../models3/ProjectTask'

function getRecentEntries(entries: { date: Date; percent: number }[], endDate: Date) {
    const sortedEntries = [...entries].sort((a, b) => a.date.getTime() - b.date.getTime())
    const aYearAgo = new Date(endDate)
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)
    const recentEntries = sortedEntries
        .filter((entry) => entry.date.getTime() - aYearAgo.getTime() >= 0)
        .sort((a, b) => a.date.getTime() - b.date.getTime())
    return recentEntries
}

const yIntercept = (x: number, y: number, slope: number) => y - slope * x

const interceptsValue = (value: number, y: number, slope: number) => (value - y) / slope

/*
 * Calculate contribution for this passage/task.
 * Don't let task effort be zero however because that makes it difficult to draw bars
 * if all the tasks have zero effort.
 */
const passageTaskEffort = (passage: Passage, task: ProjectTask) => passage.difficulty * Math.max(task.difficulty, 0.001)

export const findMostRecentVideoBeforeDate = (videos: PassageVideo[], date: Date): PassageVideo | undefined => {
    const dateDate = date.getTime()
    const i = _.findLastIndex(videos, (v) => Boolean(v.status) && Date.parse(v.creationDate) <= dateDate)
    return i >= 0 ? videos[i] : undefined
}

const findStatusAtDate = (video: PassageVideo, date: Date) => {
    const dateDate = date.getTime()
    const i = _.findLastIndex(video.statusEvents, (event) => Date.parse(event.modDate) <= dateDate)
    return i >= 0 ? video.statusEvents[i].status : ''
}

export const hasCompletedPassageTask = (allTasks: ProjectTask[], task: ProjectTask, passage: Passage, date: Date) => {
    const { videosNotDeleted } = passage
    const video = findMostRecentVideoBeforeDate(videosNotDeleted, date)
    if (!video) {
        return false
    }

    const status = findStatusAtDate(video, date)

    const videoTaskIndex = allTasks.findIndex((t) => t.id === status)
    const taskTaskIndex = allTasks.findIndex((t) => t.id === task.id)

    const completed = videoTaskIndex >= 0 && videoTaskIndex > taskTaskIndex
    return completed
}

export const totalEffort = (tasks: ProjectTask[], passages: Passage[]) => {
    let effort = 0
    passages.forEach((passage) => {
        tasks.forEach((task) => {
            effort += passageTaskEffort(passage, task)
        })
    })
    return effort
}

const completedEffort = (allTasks: ProjectTask[], taskSearchRange: ProjectTask[], passages: Passage[], date: Date) => {
    let effort = 0
    passages.forEach((passage) => {
        taskSearchRange.forEach((task) => {
            if (hasCompletedPassageTask(allTasks, task, passage, date)) {
                effort += passageTaskEffort(passage, task)
            }
        })
    })
    return effort
}
const percentComplete = (allTasks: ProjectTask[], taskSearchRange: ProjectTask[], passages: Passage[], date: Date) => {
    const completed = completedEffort(allTasks, taskSearchRange, passages, date)
    const total = totalEffort(taskSearchRange, passages)
    if (total === 0) {
        return 0
    }
    return completed / total
}

export const expectedCompletionDate = (entries: { date: Date; percent: number }[], endDate: Date) => {
    const recentEntries = getRecentEntries(entries, endDate)
    if (recentEntries.length < 2) {
        return undefined
    }
    const x0 = recentEntries[0].date.getTime()
    const y0 = recentEntries[0].percent
    const x1 = recentEntries[recentEntries.length - 1].date.getTime()
    const y1 = recentEntries[recentEntries.length - 1].percent
    const slope = (y1 - y0) / (x1 - x0)
    if (slope <= 0) {
        return undefined
    }
    const intercept = yIntercept(x0, y0, slope)
    const completionSeconds = interceptsValue(100, intercept, slope)
    const completionDate = new Date(completionSeconds)
    return completionDate
}

const earliestVideo = (videos: PassageVideo[]): PassageVideo | undefined => {
    const sortedVideos = [...videos].sort((a, b) => Date.parse(a.creationDate) - Date.parse(b.creationDate))
    return sortedVideos[0]
}

// Has currentTask completed task?
export const hasCompletedTask = (allTasks: ProjectTask[], task: ProjectTask, currentTask: ProjectTask) => {
    if (currentTask.id === 'Finished') {
        return true
    }

    if (!allTasks.find((t) => t._id === task._id || t._id === currentTask._id)) {
        return false
    }

    let completed = false
    let foundVideoTask = false
    allTasks.forEach((t) => {
        if (completed || foundVideoTask) {
            return
        }
        if (currentTask._id === t._id) {
            foundVideoTask = true // currentTask < task
            return
        }
        if (task._id === t._id) {
            completed = true // currentTask > task
        }
    })
    return completed
}

export function getPercentCompleteThroughStage(
    passages: Passage[],
    projectPlan: ProjectPlan,
    projectStage: ProjectStage,
    date: Date
) {
    const allTasks = projectPlan.stages.flatMap((stage) => stage.tasks)
    const taskRange = projectStage.tasks
    return percentComplete(allTasks, taskRange, passages, date)
}

export function getPercentCompleteThroughPlan(passages: Passage[], plan: ProjectPlan, date: Date) {
    return percentComplete(plan.tasks, plan.tasks, passages, date)
}

function getGraphVideoData(video: PassageVideo, passages: Passage[], plan: ProjectPlan, locale: string) {
    const data: { name: string; date: Date; percent: number }[] = []
    const currentTime = new Date(video.creationDate)
    const endDate = new Date(Date.now())
    while (currentTime.getTime() < endDate.getTime()) {
        data.push({
            name: currentTime.toLocaleDateString(locale, { year: 'numeric', month: 'short' }),
            date: new Date(currentTime),
            percent: getPercentCompleteThroughPlan(passages, plan, currentTime) * 100
        })
        currentTime.setDate(currentTime.getDate() + 7)
    }
    return data
}

export function getGraphData(passages: Passage[], plan: ProjectPlan, locale: string) {
    const videos = passages.flatMap((passage) => passage.videos)
    let data: { name: string; date: Date; percent: number }[] = []
    const theEarliestVideo = earliestVideo(videos)
    if (theEarliestVideo !== undefined) {
        data = getGraphVideoData(theEarliestVideo, passages, plan, locale)
    }
    return data
}
