import { createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { TabsList } from './StatusTabs'
import { useAppRoot } from '../app/RootContext'

export type FilterType =
    | 'statusShowAll'
    | 'statusShowAssignedPassages'
    | 'statusShowCurrentPortion'
    | 'statusShowCurrentPassage'
    | 'portion'
    | 'passage'

export type StatusFilter = {
    filterType: FilterType
    currentValue: string
    passageId?: string
    portionId?: string
}

interface Status {
    statusTab: TabsList
    statusFilter: StatusFilter
}

interface StatusContextValue {
    projectStatus: Status | undefined
    updateProjectStatus: (status: Status) => void
}

export const StatusContext = createContext<StatusContextValue | undefined>(undefined)

const StatusProvider: FunctionComponent = ({ children }) => {
    const { rt } = useAppRoot()
    const [projectStatus, setProjectStatus] = useState<Status>()

    const updateProjectStatus = useCallback(
        (status: Status) => {
            setProjectStatus(status)
            rt?.setDefault('status', JSON.stringify(status))
        },
        [rt]
    )

    useEffect(() => {
        const status = rt?.getDefault('status')
        if (!status) {
            updateProjectStatus({
                statusTab: TabsList.STATUS,
                statusFilter: { filterType: 'statusShowAll', currentValue: 'Show All' }
            })
        } else {
            setProjectStatus(JSON.parse(status))
        }
    }, [rt, updateProjectStatus])

    const value = useMemo(() => ({ projectStatus, updateProjectStatus }), [projectStatus, updateProjectStatus])

    return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>
}

type StatusConsumerProps = {
    children: (props: { context: StatusContextValue }) => JSX.Element
}

const StatusConsumer: FunctionComponent<StatusConsumerProps> = ({ children }) => {
    return (
        <StatusContext.Consumer>
            {(context) => {
                if (!context) {
                    throw new Error('StatusConsumer must be used within a StatusProvider')
                }
                return children({ context })
            }}
        </StatusContext.Consumer>
    )
}

function useStatus() {
    const context = useContext(StatusContext)
    if (!context) {
        throw new Error('useStatus must be used within a StatusProvider')
    }
    return context
}

export { StatusProvider, StatusConsumer, useStatus }
