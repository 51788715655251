import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useFetchBlob } from '../../hooks/useFetchBlob'
import { Root } from '../../models3/Root'
import { ImageMetadata } from '../../resources/ImageMetadata'
import { ImagesRoot } from '../../resources/ImagesRoot'
import { ImageResolution } from '../../resources/MARBLEImages'
import { RefRange } from '../../resources/RefRange'
import { ReferenceSearchInput } from '../referenceInput/ReferenceSearchInput'
import { ExpandButton, PreviousSegmentButton, NextSegmentButton, PaneCloseButton } from '../utils/Buttons'
import { LoadingIcon } from '../utils/Icons'

import './Images.css'

const MARBLE_WEBSITE_URL = 'https://marble.bible'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Images')

/*
    Displays images for search parameter references.
    Images are either from MARBLE or from projects.
    Project images may be shared with other projects.
    Captures/uploads project image file drops.

    components
        Images.tsx
            ImageDropTarget
                ImageSearchToolbar - allow user to enter search parameter, e.g. Luke 1.1-3
                ImagesList - show images matching search parameter
                    ImageViewer - show currently selected image
                        ImageMetadataEditor
                        FullSizeImageViewer
                    ImageThumbnail
                ImageUploader - upload a dropped image
                    ImageMetadataEditor.tsx
        ImagesRoot.ts - shared variables for images components
        ImageCollection.ts - handle search by reference for both project and MARBLE images
    resources
        ImageMetadata.ts
        ProjectImages.ts - get/put project images from DynamoDB via API
        MARBLEImages.ts - get MARBLE images from S3
 */

/* Functions

   - drag/drop new image, cancel
   - drag/drop new image, edit all fields, save
   - delete existing image
   - edit metadata for existing image
   - select image and show
        - step through images
   - share image, verify other project can see (disable sharing, should not appear)
   - images with numbered citations should be shown at front and in correct order
   - show solid border for shared project images
   - show dotted border for non-shared project images
 */

interface IImageSearchToolbar {
    rt: Root
    irt: ImagesRoot
    passageReferences: RefRange[]
}

const ImageSearchToolbar = ({ rt, irt, passageReferences }: IImageSearchToolbar) => {
    const [errored, setErrored] = useState(false)

    return (
        <div className="image-references-toolbar">
            <ReferenceSearchInput
                refInput={rt}
                setRefs={irt.setSearchRefs}
                errored={errored}
                setErrored={setErrored}
                stickyReferenceId="ImagesReference"
                passageReferences={passageReferences}
            />
        </div>
    )
}

interface IImageThumbnail {
    rt: Root
    image: ImageMetadata
    viewImage: () => void
}

const ImageThumbnail: FC<IImageThumbnail> = ({ rt, image, viewImage }) => {
    const { i18n } = useTranslation()

    const { imagePath } = image

    // We fetch the image rather than putting its URL directly in an <img> tag. This is
    // because we want the response to be cached by Workbox, and opaque responses are
    // not usually cached.
    const { blobUrl, isError, isLoading } = useFetchBlob(imagePath(ImageResolution.THUMBNAIL))

    const definition = image.getDefinition(i18n.language)
    const { title } = definition

    const imageTooltip = () => {
        const { copyright, references } = image

        const { description } = definition

        const lines: string[] = []
        if (title || description) {
            lines.push(title + (description ? ` / ${description}` : ''))
        }

        if (copyright) {
            lines.push(copyright)
        }

        lines.push(rt.displayableReferences(references))

        return lines.join('\n')
    }

    const tooltip = imageTooltip()

    const className = 'image-search-item'

    return (
        <div className={className} onClick={viewImage}>
            {!isError && !isLoading && blobUrl ? (
                <img
                    src={blobUrl}
                    className="image-container image-thumbnail image-button"
                    data-toggle="tooltip"
                    title={tooltip}
                />
            ) : (
                <div className="image-container image-thumbnail image-button" />
            )}
            <span className="image-title">{title}</span>
        </div>
    )
}

interface IImageViewerProps {
    image: ImageMetadata
    images: ImageMetadata[] // List of images from which image was chosen
    onClose: () => void // called when user is done viewing individual images
}

const ImageViewer = observer(({ image: initialImage, images, onClose }: IImageViewerProps) => {
    const {
        t,
        i18n: { language, dir }
    } = useTranslation()
    const [image, setImage] = useState<ImageMetadata>(initialImage)
    const [index, setIndex] = useState(images.findIndex((img) => img.id === initialImage.id))

    const { imagePath, copyright } = image
    const { blobUrl, isError, isLoading } = useFetchBlob(imagePath(ImageResolution.MEDIUM))

    const { title, description } = image.getDefinition(language)

    const goToImage = (newIndex: number) => {
        log('goToImage', newIndex, images.length)

        setIndex(newIndex)
        setImage(images[newIndex])
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation()
        if (e.key === 'Escape') {
            onClose()
        }
    }

    log('ImageViewer render', JSON.stringify(image, null, 4))

    const x = index + 1
    const y = images.length

    // example: (image number) 1 of 5
    const imagePositionMessage = t('{{x}} of {{y}}', { x, y })

    const className = 'image-thumbnail'

    return (
        <div className="image-viewer" onKeyDown={onKeyDown}>
            <div className="image-viewer-toolbar">
                <div className="image-viewer-toolbar-start">
                    {blobUrl && (
                        <ExpandButton className="image-expand-button" tooltip={t('View full size')} src={blobUrl} />
                    )}
                </div>
                {images.length > 1 && (
                    <div className="image-viewer-toolbar-middle">
                        <PreviousSegmentButton
                            enabled={index > 0}
                            onClick={() => goToImage(index - 1)}
                            tooltip={t('Go to previous image')}
                            dir={dir(language)}
                        />
                        <div className="image-pane-header-label">{imagePositionMessage}</div>
                        <NextSegmentButton
                            enabled={index !== -1 && index < images.length - 1}
                            onClick={() => goToImage(index + 1)}
                            tooltip={t('Go to next image')}
                            dir={dir(language)}
                        />
                    </div>
                )}
                <div className="image-viewer-toolbar-end">
                    <PaneCloseButton
                        className="image-viewer-close-button"
                        tooltip={t('Close pane')}
                        enabled
                        onClick={onClose}
                    />
                </div>
            </div>
            <div className="image-viewer-title">{title}</div>
            {title.toLocaleLowerCase() !== description.toLocaleLowerCase() && (
                <div className="image-viewer-description">{description}</div>
            )}
            {isError || isLoading || !blobUrl ? (
                <div className="media-placeholder" />
            ) : (
                <img src={blobUrl} className={className} />
            )}
            {copyright && <div className="image-viewer-copyright">{copyright}</div>}
        </div>
    )
})

// =======================================================================
// Display thumbnails for a list of images or the currently selected image.

interface ImagesListProps {
    rt: Root
    irt: ImagesRoot
}

const ImagesList = observer(({ rt, irt }: ImagesListProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation()
    const [currentImage, setCurrentImage] = useState<ImageMetadata>()

    const { images, loading } = irt

    useEffect(() => {
        setCurrentImage(undefined)
    }, [images])

    if (loading) {
        return <LoadingIcon className="" />
    }

    const imagesForLanguage = images.filter((image) => !image.languageCode || image.languageCode === language)
    if (!imagesForLanguage.length) {
        return <div>{t('No results found')}</div>
    }

    if (currentImage)
        return (
            <ImageViewer image={currentImage} images={imagesForLanguage} onClose={() => setCurrentImage(undefined)} />
        )

    return (
        <div className="image-area">
            <div className="image-area-images">
                <div className="image-area-grid">
                    {imagesForLanguage.map((image) => (
                        <ImageThumbnail
                            key={image.id}
                            rt={rt}
                            image={image}
                            viewImage={() => {
                                setCurrentImage(image)
                            }}
                        />
                    ))}
                </div>
            </div>
            <div className="translation-right-pane-footer">{t('curatedFrom', { websiteURL: MARBLE_WEBSITE_URL })}</div>
        </div>
    )
})

export const Images = ({ rt, passageReferences }: { rt: Root; passageReferences: RefRange[] }) => {
    // This looks weird, but is necessary because ImagesRoot is a class that maintains its
    // own state.
    // TODO: convert ImagesRoot to a hook.
    const [irt] = useState<ImagesRoot>(new ImagesRoot())

    return (
        <div className="images-area">
            <ImageSearchToolbar rt={rt} irt={irt} passageReferences={passageReferences} />
            <ImagesList rt={rt} irt={irt} />
        </div>
    )
}
