import { iso15924, Script } from 'iso-15924'

const scriptMap = new Map<string, Script>()

for (const script of iso15924) {
    scriptMap.set(script.code, script)
}

export const getScript = (scriptCode: string) => {
    return scriptMap.get(scriptCode)?.name ?? scriptCode
}
