import { Component } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { isMobileOnly } from 'react-device-detect'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { ToastContainer, ToastPosition } from 'react-toastify'

import AuthRoute from './AuthRoute'
import { BiblePericopesProvider } from './BiblePericopesContext'
import DatabaseEditor from './DatabaseEditor'
import { ExegeticalResourcesProvider } from './ExegeticalResourcesContext'
import { MarbleImagesProvider } from './MarbleImagesContext'
import { NavigationBar } from './NavigationBar'
import ProjectsEditor from './ProjectsEditor'
import { PublishedBiblesProvider } from './PublishedBiblesContext'
import { RootProvider, RootConsumer, AppRootProvider } from './RootContext'
import { isChrome, isIOS, isProductionBuild, LocalStorageKeys, websiteShortName } from './slttAvtt'
import TitleComponent from './Title'
import { TranslationResourceCachingProvider } from './TranslationResourceCachingContext'
import { AppRoot, AuthType } from '../../models3/AppRoot'
import { MarbleLemmas } from '../../resources/Lemmas'
import { ProjectBiblicalTermsViewer } from '../biblicalTerms/ProjectBiblicalTermsViewer'
import { PortionsEditor } from '../portions/PortionsEditor'
import { ProjectReview } from '../projectReview/ProjectReview'
import ProjectSettings from '../projectSettings/ProjectSettings'
import { StatusProvider } from '../status/StatusContext'
import StatusEditor from '../status/StatusEditor'
import TranslationEditor from '../translation/TranslationEditor'
import ErrorBoundary, { displayError, systemError } from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'

import 'react-toastify/dist/ReactToastify.min.css'
import './SLTool.css'

// SLTool - top level component for app
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SLTool')

const _window = window as any

const ensureSomeServiceWorkerControlsThePage = async () => {
    if (!isProductionBuild) {
        // We don't enable service workers in development environments, so don't wait
        // for them to load
        return
    }

    if (navigator.serviceWorker.controller) {
        // A service worker is already controlling the client. This means this is
        // not the first time the client has been viewed.
        return
    }

    await navigator.serviceWorker.ready

    // Force a reload to make sure that the service worker will take effect. This
    // should only happen for the very first service worker.
    window.location.reload()
}

interface SLToolProps extends RouteComponentProps {
    authType: AuthType
    username?: string
    idToken?: string
}

class SLTool extends Component<SLToolProps> {
    @observable selected = 'translation'

    narrowScreenListener?: MediaQueryList

    appRoot = new AppRoot(this.selectPage.bind(this))

    @observable resourcesFetchDone = false

    @observable serviceWorkerActivated = false

    constructor(props: any) {
        super(props)

        _window.appRoot = this.appRoot
        _window._ = this.appRoot // less to type

        this.appRoot.useMobileLayout = isMobileOnly

        if (props.authType === 'cognito' && props.username && props.idToken) {
            this.appRoot.setUser(props.username, props.idToken, props.authType)
        }

        this.selected = localStorage.getItem(LocalStorageKeys.PAGE_SELECTED) || 'translation'

        this.selectProject = this.selectProject.bind(this)

        this.fetchStaticData().catch((err) => {
            this.resourcesFetchDone = true
            log(`fetchStaticData ERROR=${err}`)
        })
    }

    async componentDidMount() {
        const { appRoot } = this
        const { history } = this.props
        log('componentDidMount')

        this.narrowScreenListener = window.matchMedia('(max-width: 900px)')
        this.setNarrowWidth(this.narrowScreenListener.matches)
        this.narrowScreenListener.addEventListener('change', (e) => {
            this.setNarrowWidth(e.matches)
        })

        let videoCacheLimitGB = 10
        if (appRoot.useMobileLayout) {
            videoCacheLimitGB = 2
        }

        localStorage.setItem(LocalStorageKeys.VIDEO_CACHE_LIMIT_GB, videoCacheLimitGB.toString())

        try {
            const rt = await appRoot.initializeProjects()

            // If the user has not created any portions for this project yet
            // force them to the portion creation page.
            const portionsCount = rt?.project.portions.length ?? -1
            if (portionsCount === 0) {
                log('!!!force /portions')
                history.push(`/portions`)
            }

            await ensureSomeServiceWorkerControlsThePage()
            this.serviceWorkerActivated = true
        } catch (error) {
            displayError(error)
        }
    }

    componentWillUnmount() {
        this.narrowScreenListener?.removeEventListener('change', (e) => {
            this.setNarrowWidth(e.matches)
        })
    }

    setNarrowWidth(value: boolean) {
        const { appRoot } = this
        appRoot.useNarrowWidthLayout = value
        appRoot.rts.forEach((root) => (root.useNarrowWidthLayout = value))
    }
    // !! Feels like this is a strange place to be fetching data relating to resources.
    // Where should it be really? appRoot?

    async fetchStaticData() {
        await MarbleLemmas.fetch()

        log('fetchStaticData done')
        this.resourcesFetchDone = true
    }

    selectPage(selection: string) {
        this.selected = selection
        localStorage.setItem(LocalStorageKeys.PAGE_SELECTED, selection)
    }

    selectProject(projectName: string) {
        this.appRoot.setCurrentProject(projectName).catch(systemError)
    }

    render() {
        const { selected, appRoot, selectProject, resourcesFetchDone, serviceWorkerActivated } = this
        const { rts, rt, iAmRoot, projectsInitialized, uiLanguageChanging } = appRoot

        log('render', rt)

        const selectPage = this.selectPage.bind(this)

        /* Debugging routes.
         * It may be useful to put the following in react-router/es/matchPath.js>matchPath
         *
         *    console.log('!!!matchPath', pathname, path)
         */

        // I think Router matches against window.location.pathname

        if (uiLanguageChanging) {
            return <div>Language changing...</div>
        }

        let message = ''
        if (!projectsInitialized) {
            message = t('Initializing projects ...')
        } else if (!resourcesFetchDone) {
            message = t('Loading resources ...')
        } else if (!serviceWorkerActivated) {
            message = t('Initializing...')
        }

        const isLoading = !projectsInitialized || !resourcesFetchDone || !serviceWorkerActivated

        let noSupportMessage = ''
        if (isIOS()) {
            noSupportMessage = t('noIOSSupport')
        } else if (!isChrome()) {
            noSupportMessage = t('You must use Chrome browser for this website.')
        }

        return (
            <AppRootProvider initialValue={appRoot}>
                <RootProvider initialValue={rt}>
                    <RootConsumer>
                        {() => (
                            <PublishedBiblesProvider>
                                <ExegeticalResourcesProvider>
                                    <BiblePericopesProvider>
                                        <MarbleImagesProvider>
                                            <TranslationResourceCachingProvider>
                                                <StatusProvider>
                                                    <TitleComponent title={websiteShortName()} />
                                                    <NavigationBar
                                                        selected={selected}
                                                        selectPage={selectPage}
                                                        selectProject={selectProject}
                                                    />

                                                    {appRoot.id_token && isLoading && (
                                                        <LoadingMessage loadingMessage={message} />
                                                    )}

                                                    {appRoot.id_token && !isLoading && noSupportMessage && (
                                                        <>
                                                            <h2>{noSupportMessage}</h2>
                                                            <p>{t('signUpDisclaimer')}</p>
                                                        </>
                                                    )}

                                                    {appRoot.id_token && !isLoading && !noSupportMessage && (
                                                        <>
                                                            {rts.length === 0 && (
                                                                <div>
                                                                    <p>
                                                                        {t(
                                                                            'You are not currently a member of any project.'
                                                                        )}
                                                                    </p>
                                                                    <p>
                                                                        {t(
                                                                            'If you are a member of an existing project, ask the project administrator to add you.'
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {rt && (
                                                                <>
                                                                    <Route
                                                                        exact
                                                                        path="/(index.html)?"
                                                                        render={() => (
                                                                            <ErrorBoundary>
                                                                                <TranslationEditor {...{ rt }} />
                                                                            </ErrorBoundary>
                                                                        )}
                                                                    />
                                                                    <Route
                                                                        path="/portions"
                                                                        render={() => (
                                                                            <AuthRoute rt={rt}>
                                                                                <ErrorBoundary>
                                                                                    <PortionsEditor rt={rt} />
                                                                                </ErrorBoundary>
                                                                            </AuthRoute>
                                                                        )}
                                                                    />
                                                                    <Route
                                                                        path="/key-terms"
                                                                        render={() => (
                                                                            <AuthRoute rt={rt}>
                                                                                <ErrorBoundary>
                                                                                    <ProjectBiblicalTermsViewer
                                                                                        rt={rt}
                                                                                    />
                                                                                </ErrorBoundary>
                                                                            </AuthRoute>
                                                                        )}
                                                                    />

                                                                    {rt.project.isEngageEnabled && (
                                                                        <Route
                                                                            path="/project-review"
                                                                            render={() => (
                                                                                <AuthRoute rt={rt}>
                                                                                    <ErrorBoundary>
                                                                                        <ProjectReview rt={rt} />
                                                                                    </ErrorBoundary>
                                                                                </AuthRoute>
                                                                            )}
                                                                        />
                                                                    )}

                                                                    <Route
                                                                        path="/project-settings"
                                                                        render={() => (
                                                                            <AuthRoute rt={rt}>
                                                                                <ErrorBoundary>
                                                                                    <ProjectSettings rt={rt} />
                                                                                </ErrorBoundary>
                                                                            </AuthRoute>
                                                                        )}
                                                                    />

                                                                    <Route
                                                                        path="/status"
                                                                        render={() => (
                                                                            <AuthRoute rt={rt}>
                                                                                <ErrorBoundary>
                                                                                    <StatusEditor rt={rt} />
                                                                                </ErrorBoundary>
                                                                            </AuthRoute>
                                                                        )}
                                                                    />

                                                                    {rt.iAmAdmin && (
                                                                        <Route
                                                                            path="/database"
                                                                            render={() => (
                                                                                <ErrorBoundary>
                                                                                    <DatabaseEditor rt={rt} />
                                                                                </ErrorBoundary>
                                                                            )}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                            {iAmRoot && (
                                                                <Route
                                                                    path="/projects"
                                                                    render={() => (
                                                                        <ErrorBoundary>
                                                                            <ProjectsEditor />
                                                                        </ErrorBoundary>
                                                                    )}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                    <ToastContainer
                                                        position={ToastPosition.TOP_LEFT}
                                                        autoClose={5000}
                                                        hideProgressBar
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        pauseOnHover
                                                    />
                                                </StatusProvider>
                                            </TranslationResourceCachingProvider>
                                        </MarbleImagesProvider>
                                    </BiblePericopesProvider>
                                </ExegeticalResourcesProvider>
                            </PublishedBiblesProvider>
                        )}
                    </RootConsumer>
                </RootProvider>
            </AppRootProvider>
        )
    }
}

export default withRouter(observer(SLTool))
