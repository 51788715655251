import { useTranslation } from 'react-i18next'

import { VisibleDocument } from './PassageDocuments'
import Select from '../select/Select'
import { groupBy } from '../utils/Helpers'
import { GenericIcon } from '../utils/Icons'

// legacy passage docs allowed empty titles and generated titles based on index
const documentTitle = (title: string, index: number) => title || `#${index + 1}`

const RenderDocumentTitle = ({ title, isGlobal }: { title: string; isGlobal: boolean }) => {
    const { t } = useTranslation()

    return (
        <>
            {title}
            {isGlobal && (
                <GenericIcon
                    className="passage-document-global-icon"
                    iconName="fa-globe"
                    iconType="fas"
                    tooltip={t('resourceVisibilityGlobal')}
                />
            )}
        </>
    )
}

interface PassageDocumentSelectorProps {
    visibleDocuments: VisibleDocument[]
    documentId: string
    setDocumentId: (value: string) => void
    idSuffix: string
    disabled?: boolean
}

interface FormatOptionLabelProps {
    label: string
    isGlobal: boolean
    isGroup: boolean
}

export const PassageDocumentSelector = ({
    visibleDocuments,
    documentId,
    setDocumentId,
    idSuffix,
    disabled
}: PassageDocumentSelectorProps) => {
    const docs = visibleDocuments.map((doc, index) => ({ ...doc, group: doc.group || '', index }))
    const optionsByGroup = groupBy(docs, ({ group }) => group)
    const groupedOptions = Object.keys(optionsByGroup).map((group) => ({
        label: group,
        options: optionsByGroup[group].map(({ document, index }) => ({
            value: document._id,
            label: documentTitle(document.title, index),
            isGlobal: document.isGlobal,
            isGroup: Boolean(group)
        }))
    }))
    const targetOptionValue = groupedOptions
        .flatMap((group) => group.options)
        .find((option) => option.value === documentId)

    const formatOptionLabel = (data: FormatOptionLabelProps) => (
        <div className={`${data.isGroup ? 'resources-dropdown-group-item' : 'resources-dropdown-item'}`}>
            <div className="resources-dropdown-header">
                <RenderDocumentTitle title={data.label} isGlobal={data.isGlobal} />
            </div>
        </div>
    )

    const isRtl = document.body.dir === 'rtl'

    return (
        <Select
            className="resources-select"
            classNamePrefix="resources-select-prefix"
            formatOptionLabel={formatOptionLabel}
            id={`resources-dropdown-${idSuffix}`}
            isDisabled={disabled}
            isSearchable
            onChange={(selected) => setDocumentId(selected?.value || '')}
            options={groupedOptions}
            styles={{
                option: (provided) => ({
                    ...provided,
                    paddingLeft: isRtl ? '0' : '27px',
                    paddingRight: isRtl ? '27px' : '0'
                })
            }}
            value={targetOptionValue}
        />
    )
}
