import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { GeneralQuestions } from './GeneralQuestions'
import { ProfileAttributesView } from './ProfileAttributesView'
import { ProjectInfo } from './ProjectInfo'
import { ReviewResponses } from './Responses'
import { useReviewProjectTabIndex } from './useReviewProjectTabIndex'
import { ReviewProject } from '../../models3/ReviewProject'
import { Root } from '../../models3/Root'
import { engageAppUrl } from '../app/slttAvtt'
import { ClipboardButton } from '../utils/Buttons'
import { buildEngageUrl } from '../utils/Engage'
import ErrorBoundary, { displayError, displayInfo } from '../utils/Errors'
import { GenericIcon } from '../utils/Icons'
import LoadingMessage from '../utils/InitializationMessage'
import { Switch } from '../utils/Switch'

import './ProjectReview.css'

export const GeneralTab = observer(
    ({
        iAmAdmin,
        iAmInterpreter,
        project,
        avttProjectName
    }: {
        iAmAdmin: boolean
        iAmInterpreter: boolean
        project: ReviewProject
        avttProjectName: string
    }) => {
        return (
            <div>
                <ProjectInfo iAmAdmin={iAmAdmin} project={project} />
                <div className="general-tab-section">
                    <GeneralQuestions
                        project={project}
                        avttProjectName={avttProjectName}
                        iAmInterpreter={iAmInterpreter}
                    />
                </div>
                <ProfileAttributesView iAmInterpreter={iAmInterpreter} project={project} />
            </div>
        )
    }
)

const ProjectReviewTabs = observer(({ rt }: ProjectReviewProps) => {
    const [tabIndex, setTabIndex] = useReviewProjectTabIndex()

    const { reviewProject } = rt.project
    const { i18n, t } = useTranslation()

    useEffect(() => {
        rt.project.setUpProjectReview()
    }, [rt.project])

    const engageUrl = buildEngageUrl(rt.name, i18n.language)

    const createLinkToEngageProject = async () => {
        if (!engageAppUrl) {
            return
        }

        try {
            await navigator.clipboard.writeText(engageUrl)
            displayInfo(t('engageLinkCopied'))
        } catch (error) {
            displayError(error, t('Failed to copy!'))
        }
    }

    return (
        <div>
            <h3>{t('projectReviewTitle')}</h3>
            {reviewProject && (
                <div className="review-project-header">
                    <Switch
                        enabled={rt.iAmAdmin}
                        value={reviewProject.isActive}
                        setValue={async (value) => {
                            await reviewProject.setIsActive(value)
                        }}
                        className="review-project-toggle-switch"
                    >
                        {t('projectReviewAllow')}
                    </Switch>
                    {reviewProject.isActive && (
                        <span className="review-project-link-container">
                            <span className="review-project-link-text">{engageUrl}</span>
                            <ClipboardButton
                                buttonClassName=""
                                className="review-project-link-icon default-blue-icon"
                                enabled
                                onClick={createLinkToEngageProject}
                            />
                        </span>
                    )}
                </div>
            )}
            {reviewProject && (
                <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                    <TabList>
                        <Tab>
                            <GenericIcon
                                iconName="fa-cog"
                                className="project-review-button default-blue-icon"
                                tooltip={t('projectReviewGeneralConfigurationTitle')}
                            />
                        </Tab>
                        <Tab>
                            <GenericIcon
                                iconName="fa-comments"
                                className="project-review-button default-blue-icon"
                                tooltip={t('projectReviewResponses')}
                            />
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <ErrorBoundary>
                            <GeneralTab
                                iAmAdmin={rt.iAmAdmin}
                                iAmInterpreter={rt.iAmInterpreter}
                                project={reviewProject}
                                avttProjectName={rt.name}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ReviewResponses rt={rt} reviewProject={reviewProject} />
                        </ErrorBoundary>
                    </TabPanel>
                </Tabs>
            )}
        </div>
    )
})

interface ProjectReviewProps {
    rt: Root
}

export const ProjectReview = observer(({ rt }: ProjectReviewProps) => {
    const { initialized, loadingMessage } = rt

    if (!initialized) {
        return <LoadingMessage {...{ loadingMessage }} />
    }

    return <ProjectReviewTabs rt={rt} />
})
