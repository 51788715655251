// /* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import './Autosuggest.css'

import { WarningIcon } from './Icons'

interface ISearchBoxProps {
    searchParameter: string
    options: string[]
    onFocus?: () => void
    onBlur?: () => void
    onEnter: () => void
    onEscape: () => void
    searchParameterChanged: (searchParameter: string) => void
    tooltip: string
    autoFocus?: boolean
    isValidInput?: boolean
    invalidTooltip?: string
    keyboardShortcuts?: { key: string; metaKey: boolean; shiftKey: boolean; handler: (event: any) => void }[]
    disableTab?: boolean
    disabled?: boolean
}

@observer
class SearchBox extends Component<ISearchBoxProps> {
    @observable inputValue = ''

    @observable filteredOptions: string[] = []

    @observable highlightedIndex: number | null = null

    @observable showSuggestions = false

    inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>()

    searchBoxRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

    constructor(props: ISearchBoxProps) {
        super(props)
        this.inputValue = props.searchParameter
    }

    componentDidMount() {
        this.setupKeyboardShortcuts()
    }

    componentDidUpdate(prevProps: ISearchBoxProps) {
        const { searchParameter } = this.props
        if (prevProps.searchParameter !== searchParameter) {
            this.inputValue = searchParameter
        }
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const { searchParameterChanged } = this.props
        this.inputValue = value
        this.filterOptions(value)
        searchParameterChanged(value)

        if (value === '') {
            this.highlightedIndex = null
            this.resetScrollPosition()
        }
    }

    handleSuggestionClick = (suggestion: string, index: number) => {
        const { searchParameterChanged } = this.props
        this.highlightedIndex = index
        this.inputValue = suggestion
        searchParameterChanged(suggestion)
        this.closeSuggestions()
    }

    handleUpDownArrowNavigation = (direction: 'up' | 'down') => {
        if (this.filteredOptions.length === 0) {
            return
        }

        if (direction === 'down') {
            if (this.highlightedIndex === null || this.highlightedIndex === this.filteredOptions.length - 1) {
                this.highlightedIndex = 0
            } else {
                this.highlightedIndex += 1
            }
        } else if (direction === 'up') {
            if (this.highlightedIndex === null || this.highlightedIndex === 0) {
                this.highlightedIndex = this.filteredOptions.length - 1
            } else {
                this.highlightedIndex -= 1
            }
        }

        if (this.highlightedIndex !== null) {
            this.inputValue = this.filteredOptions[this.highlightedIndex]
            this.setCursorToEnd()
        }

        requestAnimationFrame(() => this.scrollHighlightedIntoView())
    }

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { onEnter, onEscape, keyboardShortcuts, disableTab, searchParameter, searchParameterChanged } = this.props

        event.stopPropagation()

        if (keyboardShortcuts) {
            for (const shortcut of keyboardShortcuts) {
                const { key, metaKey, shiftKey, handler } = shortcut
                if (event.key === key && event.metaKey === !!metaKey && event.shiftKey === !!shiftKey) {
                    handler(event)
                    return
                }
            }
        }

        if (event.key === 'Tab' && !disableTab) {
            event.preventDefault()

            if (this.highlightedIndex !== null) {
                this.inputRef.current?.blur()
                this.inputRef.current?.focus()
            } else if (this.filteredOptions.length) {
                searchParameterChanged(this.filteredOptions[0])
            }
            return
        }

        switch (event.key) {
            case 'ArrowDown': {
                event.preventDefault()
                this.handleUpDownArrowNavigation('down')
                break
            }
            case 'ArrowUp': {
                event.preventDefault()
                this.handleUpDownArrowNavigation('up')
                break
            }
            case 'Enter': {
                if (this.highlightedIndex !== null) {
                    this.handleSuggestionClick(this.filteredOptions[this.highlightedIndex], this.highlightedIndex)
                } else {
                    onEnter()
                    this.inputRef.current?.blur()
                }
                break
            }
            case 'Escape': {
                this.inputValue = searchParameter
                this.inputRef.current?.blur()
                if (onEscape) onEscape()
                break
            }
            default:
                break
        }
    }

    setCursorToEnd = () => {
        const input = this.inputRef.current
        if (input) {
            const valueLength = input.value.length
            input.focus()
            input.setSelectionRange(valueLength, valueLength)
        }
    }

    setupKeyboardShortcuts() {
        const { keyboardShortcuts } = this.props
        if (keyboardShortcuts) {
            keyboardShortcuts.forEach((shortcut) => {
                window.addEventListener('keydown', (e) => {
                    if (e.key === shortcut.key && e.metaKey === shortcut.metaKey && e.shiftKey === shortcut.shiftKey) {
                        shortcut.handler(e)
                    }
                })
            })
        }
    }

    closeSuggestions = () => {
        this.filteredOptions = []
        this.showSuggestions = false
    }

    resetScrollPosition() {
        const container = document.querySelector('.search-box__suggestions-container')
        if (container) {
            container.scrollTop = 0
        }
    }

    scrollHighlightedIntoView() {
        const highlightedElement = document.querySelector('.search-box__suggestion--highlighted')
        if (highlightedElement) {
            highlightedElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        }
    }

    filterOptions(value: string) {
        const { options } = this.props
        this.filteredOptions = options.filter((opt) => opt.toLowerCase().includes(value.toLowerCase()))
        this.showSuggestions = this.filteredOptions.length > 0

        if (this.highlightedIndex !== null && this.highlightedIndex >= this.filteredOptions.length) {
            this.highlightedIndex = null
        }
    }

    render() {
        const { autoFocus, onFocus, onBlur, disabled, isValidInput, invalidTooltip, disableTab } = this.props
        const prefix = 'search-box'

        return (
            <div className="search-box-container" ref={this.searchBoxRef}>
                <div className={`${prefix}__input-wrapper`}>
                    <input
                        ref={this.inputRef}
                        type="text"
                        className={`${prefix}__input--plain`}
                        value={this.inputValue}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        onFocus={onFocus}
                        onBlur={() => {
                            this.closeSuggestions()
                            onBlur?.()
                        }}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        tabIndex={disableTab ? -1 : 0}
                    />
                    {!isValidInput && invalidTooltip && (
                        <WarningIcon className={`${prefix}__invalid-icon`} tooltip={invalidTooltip} />
                    )}
                </div>
                {this.showSuggestions && (
                    <ul className={`${prefix}__suggestions-container ${prefix}__suggestions-container--open`}>
                        {this.filteredOptions.map((option, index) => (
                            <li
                                key={option}
                                className={`${prefix}__suggestion ${
                                    this.highlightedIndex === index ? `${prefix}__suggestion--highlighted` : ''
                                }`}
                                onMouseDown={(e) => {
                                    e.preventDefault()
                                    this.handleSuggestionClick(option, index)
                                }}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    }
}

export { SearchBox }
