import { nearestHundredth } from './Helpers'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { EngageVerseTimeCode } from '../../types'
import { engageAppUrl } from '../app/slttAvtt'

const GROUP_PROJECT_SEPARATOR = ':' // not url safe
const REVIEW_GROUP_PROJECT_SEPARATOR = '_' // url safe, and not allowed in project names
const REVIEW_ID_SEPARATOR = '~' // url safe, and not allowed in project names

export const buildEngageUrl = (projectKey: string, language: string) =>
    `${engageAppUrl}/projects/rPrj${REVIEW_ID_SEPARATOR}${projectKey.replace(
        GROUP_PROJECT_SEPARATOR,
        REVIEW_GROUP_PROJECT_SEPARATOR
    )}?lang=${language}`

export const getVerseTimeCodes = (verseMarkers: ReferenceMarker[], computedDuration: number): EngageVerseTimeCode[] => {
    return verseMarkers
        .filter((marker) => marker.references.length > 0)
        .map((marker, index) => {
            const { startChapter, startVerse, endVerse } = marker.references[0].spread()
            const firstVerse = startVerse.toString()
            const lastVerse = endVerse.toString()
            const firstChapter = startChapter.toString()
            const end = index < verseMarkers.length - 1 ? verseMarkers[index + 1].time : computedDuration
            return {
                chapterId: firstChapter,
                verseId: firstVerse === lastVerse ? firstVerse : `${firstVerse}-${lastVerse}`,
                start: nearestHundredth(marker.time),
                end: nearestHundredth(end)
            }
        })
}
