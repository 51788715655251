import { FC } from 'react'

import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'

import './ProjectDataFilter.css'

interface IProjectDataFilterDropdown {
    iAmTranslator: boolean
    portions: Portion[]
    currentValue: string
    setShowAll: () => void
    setShowAssignedPassages: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
    setPortion: (portion: Portion) => void
    setPassage: (portion: Portion, passage: Passage) => void
}

const ProjectDataFilter: FC<IProjectDataFilterDropdown> = observer(
    ({
        iAmTranslator,
        portions,
        currentValue,
        setShowAll,
        setShowAssignedPassages,
        setShowCurrentPassage,
        setShowCurrentPortion,
        setPortion,
        setPassage
    }) => {
        const { t } = useTranslation()

        return (
            <Dropdown id="project-data-filter">
                <Dropdown.Toggle className="project-data-filter-toggle styled-dropdown-select">
                    {currentValue}
                </Dropdown.Toggle>
                <Dropdown.Menu className="project-data-filter-dropdown styled-dropdown-select-menu">
                    <Dropdown.Item className="project-data-filter-item" onClick={setShowAll}>
                        {`*${t('Show All')}*`}
                    </Dropdown.Item>
                    {iAmTranslator && (
                        <Dropdown.Item className="project-data-filter-item" onClick={setShowAssignedPassages}>
                            {`*${t('myPassages')}*`}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item className="project-data-filter-item" onClick={setShowCurrentPortion}>
                        {`*${t('Current Portion')}*`}
                    </Dropdown.Item>
                    <Dropdown.Item className="project-data-filter-item" onClick={setShowCurrentPassage}>
                        {`*${t('Current Passage')}*`}
                    </Dropdown.Item>
                    <Dropdown.Header>{t('Portions')}</Dropdown.Header>
                    {portions.map((portion) => (
                        <Dropdown.Item
                            key={portion._id}
                            className="project-data-filter-item"
                            onClick={() => setPortion(portion)}
                        >
                            {portion.name}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Header>{t('Passages')}</Dropdown.Header>
                    {portions.map((portion) =>
                        portion.passages.map((passage) => (
                            <Dropdown.Item
                                key={passage._id}
                                className="project-data-filter-item"
                                onClick={() => setPassage(portion, passage)}
                            >
                                {portion.getLongPassageName(passage)}
                            </Dropdown.Item>
                        ))
                    )}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
)

export default ProjectDataFilter
