import { useEffect, useState, Children, useRef, PropsWithChildren, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { GroupBase, OptionProps, components, MenuListProps } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { VariableSizeList as List } from 'react-window'

import { fetchLanguageTags, LanguageTag } from '../../../resources/LanguageTags'
import { getScript } from '../../../resources/Scripts'
import Select from '../../select/Select'
import { LoadingIcon } from '../../utils/Icons'

import './LanguageSelector.css'

type LanguageOption = {
    label: string
    value: string
    tag: LanguageTag
}

const getFullLanguageName = (languageTag: LanguageTag) => {
    const { name, localname } = languageTag
    return localname && localname.toLowerCase() !== name.toLowerCase() ? `${name} / ${localname}` : name
}

const getScriptDetail = (languageTag: LanguageTag) => {
    const tagParts = languageTag.tag.split('-')
    return tagParts.length > 1 && tagParts[1].length === 4 ? `${getScript(tagParts[1]) ?? tagParts[1]}` : ''
}

const languageToOption = (languageTag: LanguageTag) => {
    return {
        label: `${getFullLanguageName(languageTag)} [${languageTag.tag}]`,
        value: languageTag.tag,
        tag: languageTag
    }
}

const LanguageMetadata = ({ tag }: { tag: LanguageTag }) => {
    return (
        <div>
            <div className="language-selector-option-title">
                <span>{getFullLanguageName(tag)}</span>
                <span>{tag.tag}</span>
            </div>
            <div className="language-selector-option-region-script">
                <span>{tag.regionname}</span>
                <span>{getScriptDetail(tag)}</span>
            </div>
            <span className="language-selector-option-alternates">{tag.names ? tag.names.join(', ') : ''}</span>
        </div>
    )
}

const CustomOption = (props: OptionProps<LanguageOption, false, GroupBase<LanguageOption>>) => {
    const { data } = props
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Option {...props}>
            <LanguageMetadata tag={data.tag} />
        </components.Option>
    )
}

const customFilter = (option: FilterOptionOption<LanguageOption>, inputValue: string) => {
    const input = inputValue.toLowerCase()
    return Object.values(option.data.tag)
        .flatMap((property) => {
            if (Array.isArray(property)) {
                return property
            }
            if (typeof property === 'string') {
                return [property]
            }
            // Doesn't make sense for the user to search for non-string values
            return []
        })
        .map((value) => value.toLowerCase())
        .some((value) => value.includes(input))
}

const ListItem = ({
    style,
    setSize,
    index,
    children
}: PropsWithChildren<{
    style: React.CSSProperties
    setSize: (index: number, value: number) => void
    index: number
}>) => {
    const ref = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (ref.current) {
            const height = ref.current.getBoundingClientRect().height
            setSize(index, height)
        }
    }, [setSize, index])

    // We could use a ref on the div to measure the height of the children, but the styles passed in to this component
    // fixes the size of the div and therefore this doesn't work.
    return (
        <div style={style}>
            <span ref={ref}>{children}</span>
        </div>
    )
}

const DEFAULT_HEIGHT = 60

const MenuList = ({
    options,
    children,
    maxHeight,
    getValue
}: MenuListProps<LanguageOption, false, GroupBase<LanguageOption>>) => {
    const listRef = useRef<List>(null)
    const sizeMap = useRef<Record<number, number>>({})
    const setSize = useCallback((index: number, size: number) => {
        listRef.current?.resetAfterIndex(0)
        sizeMap.current = { ...sizeMap.current, [index]: size }
    }, [])
    const getSize = useCallback((index: number) => {
        const size = sizeMap.current[index]
        return size || DEFAULT_HEIGHT
    }, [])
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * DEFAULT_HEIGHT

    const childrenArray = Children.toArray(children)

    return (
        <List
            height={maxHeight}
            itemCount={Children.count(children)}
            itemSize={getSize}
            initialScrollOffset={initialOffset}
            width="100%"
            ref={listRef}
        >
            {({ index, style }) => (
                <ListItem style={style} setSize={setSize} index={index}>
                    {childrenArray[index]}
                </ListItem>
            )}
        </List>
    )
}

type ProjectLanguageSelectorProps = {
    languageCode: string
    setLanguageCode: (value: string) => void
    enabled?: boolean
}

export const LanguageSelector = ({ languageCode, setLanguageCode, enabled = true }: ProjectLanguageSelectorProps) => {
    const { t } = useTranslation()
    const [languages, setLanguages] = useState<LanguageTag[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const languageTags = await fetchLanguageTags()
                setLanguages(languageTags)
            } catch (error) {
                console.error('Error fetching languages. Some AVTT features may not work properly.', error)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [])

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    const options = languages.map(languageToOption).sort((a, b) => a.label.localeCompare(b.label))

    const handleChange = (selectedOption: LanguageOption | null) => {
        if (selectedOption) {
            setLanguageCode(selectedOption.value)
        } else {
            setLanguageCode('')
        }
    }

    const value = options.find((option) => option.value === languageCode)

    return (
        <Select
            onChange={handleChange}
            options={options}
            value={value}
            components={{ MenuList, Option: CustomOption }}
            filterOption={customFilter}
            isClearable
            isDisabled={!enabled}
            placeholder={t('languageSelectorPlaceholder')}
            styles={{
                menu: (provided) => ({
                    ...provided,
                    width: '500px'
                }),
                container: (provided) => ({
                    ...provided,
                    width: '450px'
                })
            }}
        />
    )
}
