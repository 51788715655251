import { Component, FC, Fragment } from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { ProgressBar, Table } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import dateLocales from './DateLocales'
import { getPercentCompleteThroughStage } from './ProgressUtils'
import { Project } from '../../../models3/Project'
import { Root } from '../../../models3/Root'
import { RefRange } from '../../../resources/RefRange'
import { useAppRoot } from '../../app/RootContext'
import { ColorSquare } from '../../utils/Buttons'

import 'react-datepicker/dist/react-datepicker.css'
import './ProgressReport.css'

interface IOverlappingProgressBar {
    beginPercent: number
    endPercent: number
}

const OverlappingProgressBar: FC<IOverlappingProgressBar> = (props) => {
    const { beginPercent, endPercent } = props
    const begin = Math.round(beginPercent)
    const end = Math.round(endPercent - beginPercent)

    return (
        <ProgressBar className="progress-bar-custom">
            <ProgressBar variant="info" now={begin} />
            <ProgressBar variant="success" now={end} />
        </ProgressBar>
    )
}

interface IProgressTableLegend {
    beginDate: Date
    endDate: Date
    beginColor: string
    endColor: string
}

const ProgressTableLegend: FC<IProgressTableLegend> = (props) => {
    const { t } = useTranslation()
    const { rt } = useAppRoot()

    if (!rt) {
        return null
    }

    const { dateFormatter } = rt
    const { beginDate, endDate, beginColor, endColor } = props
    const formattedBeginDate = dateFormatter.format(beginDate)
    const formattedEndDate = dateFormatter.format(endDate)
    return (
        <div>
            <div>
                <ColorSquare color={beginColor} width="20px" height="20px" />
                {t('Progress as of {{dateString}}', { dateString: formattedBeginDate })}
            </div>
            <div>
                <ColorSquare color={endColor} width="20px" height="20px" />
                {t('Progress as of {{dateString}}', { dateString: formattedEndDate })}
            </div>
        </div>
    )
}

interface IProgressTable {
    rt: Root
    project: Project
    byPortion: boolean
    beginDate: Date
    endDate: Date
}

const ProgressTable: FC<IProgressTable> = (props) => {
    const { t } = useTranslation()

    const { rt, project, byPortion, beginDate, endDate } = props
    const { portions, plans, versification } = project

    if (!plans.length) {
        return null
    }

    const allPassages = portions.map((portion) => portion.passages).reduce((a, acc) => a.concat(acc), [])
    const showItems = allPassages.filter((e) => e.difficulty > 0).length > 0
    const plan = plans[0]
    const viewableStages = plan?.viewableStages || []

    return (
        <div>
            {showItems ? (
                <div>
                    <div className="progress-table-legend">
                        <ProgressTableLegend
                            beginDate={beginDate}
                            endDate={endDate}
                            beginColor="deepskyblue"
                            endColor="green"
                        />
                    </div>
                    <Table striped style={{ width: 'fit-content' }}>
                        <thead className="progress-table-head">
                            <tr>
                                <th className="progress-table-item">{t('Portion')}</th>
                                {!byPortion && <th className="progress-table-item">{t('Passage')}</th>}
                                <th className="progress-table-item">{t('References')}</th>
                                <th className="progress-table-item">{t('Verse Count')}</th>
                                {viewableStages.map((stage) => (
                                    <th key={`${stage.name}`} className="progress-table-item">
                                        {stage.displayedName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {byPortion
                                ? portions.map((portion) => {
                                      const shouldShow =
                                          portion.passages.filter((passage) => passage.difficulty > 0).length > 0
                                      return shouldShow ? (
                                          <Fragment key={portion._id}>
                                              <tr>
                                                  <td className="progress-table-item progress-report-label">
                                                      <Link
                                                          to="/index.html"
                                                          onClick={() => {
                                                              rt.selectPage('/')
                                                              rt.setPortion(portion)
                                                          }}
                                                      >
                                                          {portion.name}
                                                      </Link>
                                                  </td>
                                                  <td className="progress-table-item progress-report-label">
                                                      {rt.displayableReferences(portion.references)}
                                                  </td>
                                                  <td className="progress-table-item progress-report-number">
                                                      {
                                                          RefRange.getAllVersesInRefRanges(
                                                              portion.references,
                                                              versification
                                                          ).length
                                                      }
                                                  </td>
                                                  {viewableStages.map((stage) => {
                                                      const { passages } = portion
                                                      const beginPercent =
                                                          getPercentCompleteThroughStage(
                                                              passages,
                                                              plan,
                                                              stage,
                                                              beginDate
                                                          ) * 100
                                                      const endPercent =
                                                          getPercentCompleteThroughStage(
                                                              passages,
                                                              plan,
                                                              stage,
                                                              endDate
                                                          ) * 100
                                                      return (
                                                          <td
                                                              key={`${stage.name}-${portion._id}`}
                                                              className="progress-table-item progress-report-progress"
                                                          >
                                                              <OverlappingProgressBar
                                                                  beginPercent={beginPercent}
                                                                  endPercent={endPercent}
                                                              />
                                                          </td>
                                                      )
                                                  })}
                                              </tr>
                                          </Fragment>
                                      ) : null
                                  })
                                : portions.map((portion) => {
                                      const shouldShow =
                                          portion.passages.filter((passage) => passage.difficulty > 0).length > 0
                                      return shouldShow ? (
                                          <Fragment key={portion._id}>
                                              <tr>
                                                  <td className="progress-table-item progress-report-label">
                                                      <Link
                                                          to="/index.html"
                                                          onClick={() => {
                                                              rt.selectPage('/')
                                                              rt.setPortion(portion)
                                                          }}
                                                      >
                                                          <b>{portion.name}</b>
                                                      </Link>
                                                  </td>
                                                  <td />
                                                  <td />
                                                  <td />
                                                  {viewableStages.map((stage) => (
                                                      <td
                                                          key={stage._id}
                                                          className="progress-table-item progress-report-progress"
                                                      />
                                                  ))}
                                              </tr>
                                              {portion.passages.map((passage) => (
                                                  <tr key={`${portion._id}-${passage._id}`}>
                                                      <td />
                                                      <td className="progress-table-item progress-report-label">
                                                          <Link
                                                              to="/index.html"
                                                              onClick={() => {
                                                                  rt.selectPage('/')
                                                                  rt.setPassage(passage)
                                                              }}
                                                          >
                                                              <b>{passage.name}</b>
                                                          </Link>
                                                      </td>
                                                      <td className="progress-table-item progress-report-label">
                                                          {rt.displayableReferences(passage.references)}
                                                      </td>
                                                      <td className="progress-table-item progress-report-number">
                                                          {
                                                              RefRange.getAllVersesInRefRanges(
                                                                  passage.references,
                                                                  versification
                                                              ).length
                                                          }
                                                      </td>
                                                      {viewableStages.map((stage) => {
                                                          const beginPercent =
                                                              getPercentCompleteThroughStage(
                                                                  [passage],
                                                                  plan,
                                                                  stage,
                                                                  beginDate
                                                              ) * 100
                                                          const endPercent =
                                                              getPercentCompleteThroughStage(
                                                                  [passage],
                                                                  plan,
                                                                  stage,
                                                                  endDate
                                                              ) * 100
                                                          return (
                                                              <td
                                                                  key={`${stage.name}-${portion._id}-${passage._id}`}
                                                                  className="progress-table-item progress-report-progress"
                                                              >
                                                                  <OverlappingProgressBar
                                                                      beginPercent={beginPercent}
                                                                      endPercent={endPercent}
                                                                  />
                                                              </td>
                                                          )
                                                      })}
                                                  </tr>
                                              ))}
                                          </Fragment>
                                      ) : null
                                  })}
                            <tr key="total">
                                <td className="progress-table-item">{t('TOTAL')}</td>
                                {!byPortion && <td />}
                                <td />
                                <td>
                                    {portions.reduce((total, portion) => {
                                        if (byPortion) {
                                            const portionVerses = RefRange.getAllVersesInRefRanges(
                                                portion.references,
                                                versification
                                            ).length
                                            return total + portionVerses
                                        }
                                        const passageVerses = portion.passages.reduce((sum, passage) => {
                                            return (
                                                sum +
                                                RefRange.getAllVersesInRefRanges(passage.references, versification)
                                                    .length
                                            )
                                        }, 0)
                                        return total + passageVerses
                                    }, 0)}
                                </td>
                                {viewableStages.map((stage) => {
                                    const passages = project.passages
                                    const beginPercent =
                                        getPercentCompleteThroughStage(passages, plan, stage, beginDate) * 100
                                    const endPercent =
                                        getPercentCompleteThroughStage(passages, plan, stage, endDate) * 100
                                    return (
                                        <td key={stage._id} className="progress-table-item progress-report-progress">
                                            <OverlappingProgressBar
                                                beginPercent={beginPercent}
                                                endPercent={endPercent}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            ) : (
                <p>{t('There are no passages with nonzero difficulty.')}</p>
            )}
        </div>
    )
}

interface IReportBy {
    currentlySelected: string
    onSelect: (item: string) => void
}

const ReportBy: FC<IReportBy> = (props) => {
    const { t } = useTranslation()

    const { currentlySelected, onSelect } = props
    return (
        <div>
            <select
                className="custom-select progress-report-dropdown-menu"
                data-id="progress-report-dropdown-menu"
                value={currentlySelected}
                onChange={(e) => onSelect(e.target.value)}
            >
                <option key="portion" value="portion">
                    {t('Portion')}
                </option>
                <option key="passage" value="passage">
                    {t('Passage')}
                </option>
            </select>
        </div>
    )
}
interface IProgressReportHeader {
    reportBy: string
    setReportBy: (item: string) => void
    startDate: Date
    endDate: Date
    setStartDate: (date: Date) => void
    setEndDate: (date: Date) => void
    rt: Root
}

const ProgressReportHeader: FC<IProgressReportHeader> = ({
    rt,
    reportBy,
    setReportBy,
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {
    const { t, i18n } = useTranslation()

    const { language } = i18n
    const { dateFormat } = rt.project
    const localeData = dateLocales[language]
    registerLocale(language, localeData)

    return (
        <div className="progress-report-header">
            <div className="progress-report-controls">
                <div className="progress-report-header-item">
                    {t('Report by')}
                    <ReportBy currentlySelected={reportBy} onSelect={setReportBy} />
                </div>
                <div className="progress-report-header-item">
                    {t('Period begin date')}
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                            locale={language}
                            dateFormat={dateFormat}
                        />
                    </div>
                </div>
                <div className="progress-report-header-item">
                    {t('Period end date')}
                    <div>
                        <DatePicker
                            selected={endDate}
                            onChange={setEndDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            locale={language}
                            dateFormat={dateFormat}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface IProgressReport {
    rt: Root
}

class ProgressReport extends Component<IProgressReport> {
    @observable startDate: Date

    @observable endDate: Date

    @observable reportBy = `passage`

    constructor(props: IProgressReport) {
        super(props)
        const today = new Date()
        const todayYear = today.getFullYear()
        const quarters = [
            new Date(todayYear, 0),
            new Date(todayYear, 3),
            new Date(todayYear, 6),
            new Date(todayYear, 9)
        ]
        const todayMonth = today.getMonth()
        if (todayMonth < 3) {
            this.startDate = quarters[0]
        } else if (todayMonth < 6) {
            this.startDate = quarters[1]
        } else if (todayMonth < 9) {
            this.startDate = quarters[2]
        } else {
            this.startDate = quarters[3]
        }
        this.endDate = today
    }

    render() {
        const { rt } = this.props
        const { project } = rt
        const { reportBy, startDate, endDate } = this
        return (
            <div>
                <ProgressReportHeader
                    rt={rt}
                    reportBy={reportBy}
                    setReportBy={(item) => (this.reportBy = item)}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(date) => (this.startDate = date)}
                    setEndDate={(date) => (this.endDate = date)}
                />
                <div className="progress-report-separator" />
                <div className="progress-report-table">
                    <ProgressTable
                        rt={rt}
                        project={project}
                        byPortion={reportBy === `portion`}
                        beginDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        )
    }
}

export default observer(ProgressReport)
