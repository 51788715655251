import { useEffect, useMemo } from 'react'

import { StylesConfig, GroupBase } from 'react-select'

import { Step } from '../../resources/ExegeticalResources'
import Select from '../select/Select'

interface ExegeticalResourceSelectorProps {
    stepId: string
    setStepId: (id: string) => void
    idSuffix: string
    stepsMap: Map<string, Step>
}

interface Option {
    value: string
    label: string
}

interface FirstDropdownItem {
    id: string
    title: string
}

const dropdownItems = (pericopesMap: Map<string, Step>): FirstDropdownItem[] => {
    return Array.from(pericopesMap.values()).map(({ id, title }) => ({ id, title }))
}

export const ExegeticalResourceSelector = ({
    stepId,
    setStepId,
    idSuffix,
    stepsMap
}: ExegeticalResourceSelectorProps) => {
    const items = useMemo(() => dropdownItems(stepsMap), [stepsMap])
    const selectedItem = useMemo(() => items.find((item) => stepId === item.id), [items, stepId])

    const isRtl = document.body.dir === 'rtl'

    const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
        menu: (provided) => ({
            ...provided,
            width: 'auto',
            minWidth: '100%',
            whiteSpace: 'nowrap',
            left: 'auto',
            right: 0
        }),
        menuList: (provided) => ({
            ...provided,
            minHeight: '340px'
        }),
        option: (provided) => ({
            ...provided,
            paddingLeft: isRtl ? '0' : '27px',
            paddingRight: isRtl ? '27px' : '0'
        })
    }

    // select first step if no step is selected
    useEffect(() => {
        if (!selectedItem?.id && items.length > 1 && items[1].id) {
            setStepId(items[1].id)
        }
    }, [items, selectedItem, setStepId])

    const handleOnChange = (selectedOption: Option | null) => {
        if (selectedOption) {
            const id = selectedOption.value
            setStepId(id)
        }
    }

    const groupedOptions = items.reduce((acc, { id, title }) => {
        if (id.includes('heading')) {
            acc.push({ label: title, options: [] })
        } else {
            const lastGroup = acc[acc.length - 1] ?? acc[acc.push({ label: 'Ungrouped', options: [] }) - 1]
            lastGroup.options.push({ value: id || '', label: title })
        }
        return acc
    }, [] as { label: string; options: { value: string; label: string }[] }[])

    const targetOption = groupedOptions
        .flatMap((group) => group.options)
        .find((option) => selectedItem?.id === option.value)

    return (
        <Select
            id={`resources-select-${idSuffix}`}
            className="resources-select"
            value={targetOption}
            onChange={handleOnChange}
            options={groupedOptions}
            isSearchable={false}
            menuShouldBlockScroll
            styles={customStyles}
        />
    )
}
