import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { GenericIconButton, PauseButton, PlayButton } from '../utils/Buttons'

import './SimpleAudioPlayer.css'

const DEFAULT_SKIP_TIME = 10 // seconds

interface SimpleAudioPlayerProps {
    url: string
    startTime?: number
    endTime?: number
    onTimeUpdate?: (currentTime: number) => void
    onPlay?: () => void
    onPause?: () => void
    getNextSkipTime?: (currentTime: number, limit: number) => number
    getPreviousSkipTime?: (currentTime: number, limit: number) => number
    nextSkipTooltip?: string
    previousSkipTooltip?: string
    audioPlayerRef: React.RefObject<HTMLAudioElement>
    isPlaying: string
    setIsPlaying: (isPlaying: string) => void
    idSuffix: string
    isEnabled: boolean
}

export const SimpleAudioPlayer = observer(
    ({
        url,
        startTime,
        endTime,
        onTimeUpdate,
        onPlay,
        onPause,
        getNextSkipTime,
        getPreviousSkipTime,
        nextSkipTooltip,
        previousSkipTooltip,
        audioPlayerRef,
        isPlaying,
        setIsPlaying,
        idSuffix,
        isEnabled
    }: SimpleAudioPlayerProps) => {
        const { t } = useTranslation()

        useEffect(() => {
            return () => {
                if (isPlaying === idSuffix) {
                    setIsPlaying('')
                }
            }
        }, [setIsPlaying, idSuffix, isPlaying])

        const updateTime = () => {
            const currentTime = audioPlayerRef.current?.currentTime
            if (currentTime === undefined || !onTimeUpdate) {
                return
            }

            onTimeUpdate(currentTime)
            if (endTime !== undefined && currentTime > endTime) {
                audioPlayerRef.current?.pause()
            } else if (startTime !== undefined && currentTime < startTime) {
                audioPlayerRef.current?.pause()
            }
        }

        const play = () => {
            const { current } = audioPlayerRef
            if (current) {
                if (startTime !== undefined && current.currentTime <= startTime) {
                    current.currentTime = startTime
                }

                if (endTime !== undefined && current.currentTime >= endTime) {
                    current.currentTime = startTime ?? 0
                }

                if (Math.abs(current.duration - current.currentTime) < 0.5) {
                    current.currentTime = startTime ?? 0
                }

                current?.play()
            }
        }

        const pause = () => {
            audioPlayerRef.current?.pause()
        }

        const skipAhead = () => {
            const { current } = audioPlayerRef
            if (current) {
                current.currentTime = getNextSkipTime
                    ? getNextSkipTime(current.currentTime, endTime ?? current.duration)
                    : Math.min(current.currentTime + DEFAULT_SKIP_TIME, current.duration)
            }
        }

        const skipBack = () => {
            const { current } = audioPlayerRef
            if (current) {
                current.currentTime = getPreviousSkipTime
                    ? getPreviousSkipTime(current.currentTime, startTime ?? 0)
                    : Math.min(current.currentTime - DEFAULT_SKIP_TIME, 0)
            }
        }

        const goToBeginning = () => {
            const { current } = audioPlayerRef
            if (current) {
                current.currentTime = startTime ?? 0
            }
        }

        const goToEnd = () => {
            const { current } = audioPlayerRef
            if (current) {
                current.currentTime = endTime ?? current.duration
            }
        }

        return (
            <div className="simple-audio-player">
                <audio
                    src={url}
                    className="audio-player"
                    ref={audioPlayerRef}
                    onPlay={() => {
                        setIsPlaying(idSuffix)
                        if (onPlay) {
                            onPlay()
                        }
                    }}
                    onPause={() => {
                        setIsPlaying('')
                        if (onPause) {
                            onPause()
                        }
                    }}
                    onTimeUpdate={updateTime}
                />
                <div className="centered-controls">
                    <GenericIconButton
                        iconName="fa-backward-step"
                        iconType="fas"
                        className="published-audio-change-time-button"
                        enabled={isEnabled}
                        onClick={goToBeginning}
                        tooltip={t('goToBeginning')}
                    />
                    <GenericIconButton
                        iconName="fa-rotate-left"
                        className="published-audio-change-time-button"
                        iconType="fas"
                        enabled={isEnabled}
                        onClick={skipBack}
                        tooltip={previousSkipTooltip ?? t('goBack10Seconds')}
                    />
                    {!isEnabled ? (
                        <PlayButton
                            className="published-audio-playback-button"
                            onClick={play}
                            enabled={isEnabled || !isPlaying}
                            tooltip={t('Play')}
                        />
                    ) : (
                        <PauseButton
                            className="published-audio-playback-button"
                            onClick={pause}
                            enabled
                            tooltip={t('Pause.')}
                        />
                    )}
                    <GenericIconButton
                        iconName="fa-rotate-right"
                        className="published-audio-change-time-button"
                        iconType="fas"
                        enabled={isEnabled}
                        onClick={skipAhead}
                        tooltip={nextSkipTooltip ?? t('goForward10Seconds')}
                    />
                    <GenericIconButton
                        iconName="fa-forward-step"
                        iconType="fas"
                        className="published-audio-change-time-button"
                        enabled={isEnabled}
                        onClick={goToEnd}
                        tooltip={t('goToEnd')}
                    />
                </div>
            </div>
        )
    }
)
